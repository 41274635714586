@font-face {
  font-family: 'Inter';
  src: local('Inter Semi Bold'), local('Inter-Semi-Bold'), url('./assets/fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), local('Inter-Regular'), url('./assets/fonts/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Medium'), local('Inter-Medium'), url('./assets/fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Compact Display';
  src: local('SF Compact Display Semibold'), local('SF-Compact-Display-Semibold'),
    url('./assets/fonts/SFCompactDisplay-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: inherit;
}

body {
  font-family: 'Inter', sans-serif;
}

a {
  text-decoration: none;
  color: #121212;
}
