.container {
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: -webkit-fill-available; // take bottom menu on large iOS devices into consideration (i.e. make it 100vh - menu height)
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
}

.logo {
  width: 177px;
  height: 177px;
  border-radius: 27px;
}

.title {
  margin: 20px 0 25px;
  font-family: 'SF Compact Display';
  font-size: 23px;
  font-weight: 600;
  line-height: 27px;
  text-align: center;
  color: #274d89;
}

.stats {
  display: flex;
  column-gap: 25px;
}

.info {
  display: flex;
  align-items: center;
}

.amount {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.stars {
  margin-left: 5px;
  display: flex;
  column-gap: 2px;
}

.peopleIcon {
  margin-left: 9px;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #6f6f6f;
}

.button {
  margin-bottom: 65px;
  padding: 0px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  color: #ffffff;
  background: #35a852;
  border: none;
  border-radius: 12px;
  box-shadow: 0px 4px 24px rgba(53, 168, 82, 0.5);

  &:hover {
    background: #138e32;
  }
}
